import styled from "styled-components"

export const HomeWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  height: 70vh;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`
export const BrandLogo = styled.div`
  .brand-image {
    height: 420px;
    width: 420px;

    img {
      height: 420px;
      width: 420px;
      padding: 0;
    }

    @media (max-width: 992px) {
      height: 280px;
      width: 280px;
      padding: 0;

      img {
        height: 280px;
        width: 280px;
      }
    }
  }
`

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;

  h3 {
    padding: 20px 10px;
    letter-spacing: 4px;
    font-family: ${({ theme }) => theme.fonts[0]};
    color: ${({ theme }) => theme.colors.white};
    font-size: 2.2em;
  }
`
