import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { BrandLogo, HomeWrapper, LinkWrapper } from "./Home.styles"

export const Home = () => {
  return (
    <HomeWrapper>
      <BrandLogo>
        <StaticImage
          className="brand-image"
          imgClassName="brand-image"
          src={"../../images/logo-no-bg.webp"}
          alt={"Places Unbound"}
          placeholder="blurred"
        />
      </BrandLogo>
      <LinkWrapper>
        <Link to="/services/design">
          <h3>design</h3>
        </Link>
        <h3>+</h3>
        <Link to="/services/research">
          <h3>research</h3>
        </Link>
      </LinkWrapper>
    </HomeWrapper>
  )
}
